import React from 'react';
import './App.css';
import Main from './components/Main';

class App extends React.Component {

  state = {
    language: 'English',
    menuExpanded: false
  }

  handleChangeLanguage = () => {
    let newLanguage;
    newLanguage = this.state.language === 'English' ? 'German' : 'English';
    this.setState({
      language: newLanguage
    })
  }

  handleExpandMenu = () => {
    let newMenuExpanded = this.state.menuExpanded ? false : true;
    this.setState({
      menuExpanded: newMenuExpanded
    })
  }

  render() {
    return (
      <div className="App">
        <Main language={this.state.language} changeLanguage={this.handleChangeLanguage} expandMenu={this.handleExpandMenu} menuExpanded={this.state.menuExpanded}/>
      </div>
    );
  }
}

export default App;
