import React from "react";
import menupic from "../pics/menu.svg"

export default function Menu(props) {

    let englishAbout = 'About';
    let germanAbout = 'Über mich';
    let about = props.language === 'English' ? englishAbout : germanAbout;

    let englishServices = 'Services';
    let germanServices = 'Leistungen';
    let services = props.language === 'English' ? englishServices : germanServices;

    let englishContact = 'Contact';
    let germanContact = 'Kontakt';
    let contact = props.language === 'English' ? englishContact : germanContact;

  return (
    <div className="nav-box">
      <nav>
        <div className="menu-box">
          <img onClick={props.expandMenu} className="menupic" src={menupic} alt="Menu"/>

        {
          props.menuExpanded && <div>
            <ul className="nav-bar-phone">
            <li>
            <a href="#about-me">{about}</a>
          </li>
          <li>
            <a href="#services">{services}</a>
          </li>
          <li>
            <a href="#contact">{contact}</a>
          </li>
            </ul>
          </div>
        }

        </div>

        <ul className="nav-bar">
          <li>
            <a href="#about-me">{about}</a>
          </li>
          <li>
            <a href="#services">{services}</a>
          </li>
          <li>
            <a href="#contact">{contact}</a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
