import React from 'react';
import jimpic from '../pics/jamesparmentertea.JPG'

export default function About(props) {

    let englishHi = 'Hi there!';
    let germanHi = 'Hallo!';
    let hi = props.language === 'English' ? englishHi : germanHi;

    let englishAboutMe = `I'm James, a translator originally from Essex/England now based in Berlin/Germany. I have a degree in German and French, over 10 years of experience and a passion for making things make sense in all of my languages.`;
    let germanAboutMe = 'Ich bin James, ein Übersetzer aus Essex/England, der heute in Berlin lebt. Ich habe Deutsch und Französisch studiert und kann inzwischen auf mehr als zehn Jahre Arbeitserfahrung zurückblicken.';
    let aboutMe = props.language === 'English' ? englishAboutMe : germanAboutMe;

    return (
        <div id='about-me'>
            <img src={jimpic} alt="James Parmenter"/>
            <h2>{hi}</h2>
            <p>{aboutMe}</p>
        </div>
    )
}
