import React from 'react'

export default function Header(props) {
    let englishIntro = 'Let me translate that for you.';
    let germanIntro = 'Lassen Sie mich das für Sie übersetzen.';
    let intro = props.language === 'English' ? englishIntro : germanIntro;

    return (
        <div id='header'>
            <h1>James Parmenter</h1>
            <button id='intro' onClick={props.changeLanguage}>
            <span>{intro}</span>
            </button>
        </div>
    )
}
