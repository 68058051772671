import React from 'react';
import Nav from './Nav';
import Header from './Header';
import Footer from './Footer';
import About from './About';
import Services from './Services';
import Contact from './Contact'

export default function MainEnglish(props) {
    return (
        <div>
            <Nav language={props.language} expandMenu={props.expandMenu} menuExpanded={props.menuExpanded}/>
            <Header language={props.language} changeLanguage={props.changeLanguage}/>
            <About language={props.language}/>
            <hr></hr>
            <Services language={props.language}/>
            <hr></hr>
            <Contact language={props.language}/>
            <Footer/>
        </div>
    )
}
