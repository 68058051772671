import React from 'react';
import translatepic from '../pics/translate.png';
import proofreadpic from '../pics/proofread.png';
import localisepic from '../pics/localise.png'

export default function Services(props) {

    let englishWhatCanIDo = 'What can I do for you?';
    let germanWhatCanIDo = 'Was kann ich für Sie tun?';
    let whatCanIDo = props.language === 'English' ? englishWhatCanIDo : germanWhatCanIDo;

    let englishTranslate = 'Translate';
    let germanTranslate = 'Übersetzen';
    let translate = props.language === 'English' ? englishTranslate : germanTranslate;

    let englishTranslateBody = `I like a translation that doesn't sound like one. Let me translate your words from German or French to English - or vice versa - and make sure they still say what you want them to.`;
    let germanTranslateBody = 'Ich mag Übersetzungen, die nicht nach Übersetzungen klingen. Lassen Sie mich Ihre Texte von Deutsch oder Französisch auf Englisch übersetzen - oder umgekehrt - ohne dass der Sinn dabei verloren geht.';
    let translateBody = props.language === 'English' ? englishTranslateBody : germanTranslateBody;

    let englishProofread = 'Proofread';
    let germanProofread = 'Korrekturlesen';
    let proofread = props.language === 'English' ? englishProofread : germanProofread;

    let englishProofreadBody = `Whether you're looking to get rid of awkward typos that spell-check didn't catch or are hoping for a more native-sounding sentence flow: I'll help you polish your writing.`;
    let germanProofreadBody = 'Ob peinliche Tippfehler, die sich an der Autokorrektur vorbeigeschummelt haben, oder Sätze, die noch nicht so richtig flüssig klingen wie bei Muttersprachlern: Ich helfe ihnen, ihre Texte rundzumachen.';
    let proofreadBody = props.language === 'English' ? englishProofreadBody : germanProofreadBody;

    let englishLocalise = 'Localise';
    let germanLocalise = 'Lokalisieren';
    let localise = props.language === 'English' ? englishLocalise : germanLocalise;

    let englishLocaliseBody = `Or should I say: localize? It can be tricky to catch all instances of American spelling in a British text or to figure out how to translate a local German term at all. I'll go over every little detail to make sure your text never sounds out of place.`;
    let germanLocaliseBody = 'Übersetzt man das jetzt mit "localise" oder "localize"? Das kommt ganz darauf an, wen Sie ansprechen möchten. Von unterschiedlichen Schreibweisen bei britischem und amerikanischem Englisch bis zu kniffligen Übersetzung regionaler deutscher Begriffe - ich nehmen jedes Wort unter die Lupe, damit nichts deplatziert wirkt.';
    let localiseBody = props.language === 'English' ? englishLocaliseBody : germanLocaliseBody;


    return (
        <div id='services'>
            <h2>{whatCanIDo}</h2>
        <div>
            <article>
                <img className="services-icon" src={translatepic} alt="Translate"></img>
                <h3>{translate}</h3>
                <p>{translateBody}</p>
            </article>
            <article>
                <img className="services-icon" src={proofreadpic} alt="Proofread"></img>
                <h3>{proofread}</h3>
                <p>{proofreadBody}</p>
            </article>
            <article>
                <img className="services-icon" src={localisepic} alt="Localise"></img>
                <h3>{localise}</h3>
                <p>{localiseBody}</p>
            </article>
        </div>
        </div>
    )
}
