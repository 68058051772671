import React from 'react'

export default function Contact(props) {

    let englishContact = 'Contact me';
    let germanContact = 'Kontakt';
    let contact = props.language === 'English' ? englishContact : germanContact;

    let englishContactBody = `I'm currently available for freelance work. If you need anything translated, proofread or localised, just get in touch!`;
    let germanContactBody = 'Sie brauchen Unterstützung beim Übersetzen, Korrekturlesen oder Lokalisieren? Schreiben Sie mir!';
    let contactBody = props.language === 'English' ? englishContactBody : germanContactBody;

    let englishContactButton = 'MESSAGE ME';
    let germanContactButton = 'KONTAKTIEREN';
    let contactButton = props.language === 'English' ? englishContactButton : germanContactButton;

    return (
        <div id='contact'>
            <h2>{contact}</h2>
            <p>{contactBody}</p>
            <a className="button-contact" href="mailto:james@jamesparmenter.eu">{contactButton}</a>
        </div>
    )
}
